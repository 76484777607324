import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		userAuthenticated: Cookies.get('auS'),
		superUser: false,
		isSuperUser: sessionStorage.getItem("isSuperUser") === "true" ? true : false || false,
		superUserMode: false,
	},
	reducers: {
		setUserAuthenticated(state, action) {
			const currState = state;
			currState.userAuthenticated = action.payload;
		},
		setSuperUserMode(state, action) {
			const currState = state;
			currState.isSuperUser = action.payload;
			sessionStorage.setItem("isSuperUser", action.payload)
		},
		setIsSuperUser(state, action) {
			const currState = state;
			currState.superUser = action.payload;
			// currState.isSuperUser = action.payload;
			// currState.superUserMode = action.payload;
		},
	},
});

export const authActions = authSlice.actions;
export default authSlice;
