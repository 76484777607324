import { Component } from 'react';
import errorHandler from '../../utils/errorHandler';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError = () => ({ hasError: true });

	componentDidCatch(error, info) {
		if (error) {
			this.setState({ hasError: true }, () => {
				errorHandler(error, info);
			});
		}
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;
		if (hasError) {
			return <div />;
		}
		return children;
	}
}

const mode = process.env.REACT_APP_MODE;

export function fallbackErrorRender(props) {
	const { error, resetErrorBoundary } = props;
	return (
		<div className='section-error'>
			<p>Something went wrong:</p>
			<pre className='text-red-500'>{error.message}</pre>
			{mode === 'development' ||
				(['localhost', '127.0.0.1'].includes(window.location.hostname) && <pre className='text-red-500'>{error.stack}</pre>)}
			<div className='d-flex justify-content-center gap-2'>
				<button className='btn' onClick={resetErrorBoundary}>
					Try again
				</button>
				<a href='/' className='btn'>
					Home
				</a>
			</div>
		</div>
	);
}
