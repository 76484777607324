import { configureStore } from '@reduxjs/toolkit';
// import * as Sentry from '@sentry/react';

import authSlice from './slices/authSlice';
import projectSlice from './slices/projectSlice';
import signSlice from './slices/signSlice';
import contentSlice from './slices/contentSlice';

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    project: projectSlice.reducer,
    sign: signSlice.reducer,
    content: contentSlice.reducer,
  },
  // enhancers: [sentryReduxEnhancer]
});

export default store;
