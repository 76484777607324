import Cookies from "js-cookie";

let arr = {
    // base_api: "http://13.48.233.47/",
    // base_api: "http://51.107.214.79/",
    base_api: `${process.env.REACT_APP_API_URL}`,
    token: `${Cookies.get("au0T")} ${Cookies.get("au0")}`,
    GA_ID: process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_GA4 : ''
}

export const setArr = (newArr) => {
    arr = newArr
}
export default arr
