import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerApi from '../../api/CustomerApi';
import SignTypeApi from '../../api/SignTypeApi';

export const setSignTypes = createAsyncThunk('project/setSignType', async (values) => {
	const params = { ...values, max_size: true, ordering: 'name' }
	const signTypeApi = new SignTypeApi();
	const response = await signTypeApi.getSignSubTypes(params);
	return response.data.results || response.data;
});

export const setContacts = createAsyncThunk('project/setContacts', async (params = { max_size: true }) => {
	const customerApi = new CustomerApi();
	const response = await customerApi.getAllContacts(params);
	return response.data.results;
});

export const setRegions = createAsyncThunk('project/setRegions', async (params = { max_size: true }) => {
	const customerApi = new CustomerApi();
	const response = await customerApi.getAllRegions(params);
	return { data: response.data.results };
});

const defaultActiveFilter = { mySign: false, status: '', order: '' }

const projectSlice = createSlice({
	name: 'project',
	initialState: {
		contactList: [],
		regionList: [],
		signSubTypeList: null,

		activeTab: 0,
		isCreateSignWithProject: false,
		editorStatus: '',
		rightClickAction: null,
		activeFilter: defaultActiveFilter,
		isActiveFilter: false,
	},
	reducers: {
		setActiveTab(state, action) {
			const currState = state;
			currState.activeTab = action.payload;
		},
		setCreateSignWithProject(state, action) {
			const currState = state;
			currState.isCreateSignWithProject = !!action.payload;
		},
		setEditorStatus(state, action) {
			const currState = state;
			currState.editorStatus = action.payload;
		},
		setRightClickAction(state, action) {
			const currState = state;
			currState.rightClickAction = action.payload;
		},
		// Filter sign inside project on Editor
		setActiveFilter(state, action) {
			const currState = state;
			currState.activeFilter = action.payload || defaultActiveFilter;
		},
		setIsActiveFilter(state, action) {
			const currState = state;
			currState.isActiveFilter = !!action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(setSignTypes.fulfilled, (state, action) => {
				const currState = state;
				currState.signSubTypeList = action.payload;
			})
			.addCase(setContacts.fulfilled, (state, action) => {
				const currState = state;
				currState.contactList = action.payload;
			})
			.addCase(setRegions.fulfilled, (state, action) => {
				const currState = state;
				const { data } = action.payload;

				if (data) {
					currState.regionList = data;
				}
			});
	},
});

export const projectActions = projectSlice.actions;
export default projectSlice;
