import { createSlice } from '@reduxjs/toolkit';

const signSlice = createSlice({
	name: 'sign',
	initialState: {
		isElementToRender: false,
		removedState: null,
		signWarning: null,
	},
	reducers: {
		setElementsToRender(state, action) {
			const currState = state;
			currState.isElementToRender = action.payload;
		},
		removeSelectedState(state, action) {
			const currState = state;
			currState.removedState = action.payload;
		},
		setSignWarning(state, action) {
			const currState = state;
			currState.signWarning = action.payload;
		}
	},
});

export const signActions = signSlice.actions;
export default signSlice;
