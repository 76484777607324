function errorHandler(error, info) {
	const d = new Date();
	const currentDate = `${d.getDate()}-${d.toLocaleString('default', { month: 'long' })}-${d.getFullYear()}`;
	const currentTime = `${`0${d.getHours()}`.substr(-2)}:${`0${d.getMinutes()}`.substr(-2)}`;

	const err = JSON.stringify(error);
	let errText = '';
	if (error) {
		errText = err === '{}' ? `${error.message} at ${error.stack}` : '';
	}
	const data = {
		error_from: 'Site name',
		error_when: `date: ${currentDate}  -  time: ${currentTime}`,
		error: errText,
		info: !info.componentStack ? info : JSON.stringify(info.componentStack),
	};
	console.log('error', data);
}
export default errorHandler;
