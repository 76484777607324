import axios from "axios";
import config from "../components/Helper/config"

const axiosInstance = axios.create({
    baseURL: config.base_api,
    headers: { Authorization: config.token, "Content-Type": "application/json" },
});
class CustomerApi {
    baseURL = config.base_api;
    token = config.token;

    getAllCustomers(params) {
        const method = 'get'
        const url = this.baseURL + `customers/`
        return axiosInstance({ method, url, params })
    }

    addAction(data, param) {
        const method = 'post'
        let url
        if (param === 'customer') {
            url = this.baseURL + `customers/`
        } else {
            url = this.baseURL + `contacts/`
        }
        return axiosInstance({ method, url, data })
    }

    updateAction(id, data, param) {
        const method = 'patch'
        let url
        if (param === 'customer') {
            url = this.baseURL + `customers/${id}/`
        } else {
            url = this.baseURL + `contacts/${id}/`
        }
        return axiosInstance({ method, url, data })
    }

    deleteAction(data, param) {
        const method = 'delete'
        let url
        if (param === 'customer') {
            url = this.baseURL + `customers/delete_bulk/`
        } else {
            url = this.baseURL + `contacts/delete_bulk/`
        }
        return axiosInstance({ method, url, data })
    }

    getAllRegions(params) {
        const method = 'get'
        const url = this.baseURL + `regions/`
        return axiosInstance({ method, url, params })
    }

    addRegion(data) {
        const method = 'post'
        const url = this.baseURL + `regions/`
        return axiosInstance({ method, url, data })
    }

    updateRegion(id, data) {
        const method = 'patch'
        const url = this.baseURL + `regions/${id}/`
        return axiosInstance({ method, url, data })
    }

    deleteBulkRegion(ids) {
        const method = 'delete'
        const url = this.baseURL + `regions/delete_bulk/`
        return axiosInstance({ method, url, data: { region_ids: ids } })
    }

    getAllContacts(params) {
        const method = 'get'
        const url = this.baseURL + `contacts/`
        return axiosInstance({ method, url, params })
    }

    getAllProjects(params) {
        const method = 'get'
        const url = this.baseURL + `projects/`
        return axiosInstance({ method, url, params })
    }

    addProject(data, params) {
        const method = 'post'
        const url = this.baseURL + `projects/`
        return axiosInstance({ method, url, data, params })
    }

    updateProject(id, data) {
        const method = 'patch'
        const url = this.baseURL + `projects/${id}/`
        return axiosInstance({ method, url, data })
    }

    getAllStatuses(params) {
        const method = 'get'
        const url = this.baseURL + `sign_statuses/`
        return axiosInstance({ method, url, params })
    }

    addStatus(data) {
        const method = 'post'
        const url = this.baseURL + `sign_statuses/`
        return axiosInstance({ method, url, data })
    }

    updateStatus(id, data) {
        const method = 'patch'
        const url = this.baseURL + `sign_statuses/${id}/`
        return axiosInstance({ method, url, data })
    }

    deleteStatus(id) {
        const method = 'delete'
        const url = this.baseURL + `sign_statuses/${id}/`
        return axiosInstance({ method, url })
    }

    deleteBulkStatus(ids) {
        const method = 'delete'
        const url = this.baseURL + `sign_statuses/delete_bulk/`
        return axiosInstance({ method, url, data: { statuses: ids } })
    }
}

export default CustomerApi;
