import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerApi from '../../api/CustomerApi';

const customerApi = new CustomerApi();

export const getSignStatuses = createAsyncThunk('content/setSignStatuses', async (params) => {
	const response = await customerApi.getAllStatuses({ max_size: true, ordering: 'workflow_index' });
	return response.data;
});

const contentSlice = createSlice({
	name: 'content',
	initialState: {
		signStatuses: [],
		signProbabilityStatuses: [],
	},
	reducers: {
		setProbabilityStatus(state, action) {
			const currState = state;
			const currSignStatuses = currState.signStatuses;
            const allStatus = action.payload;

			const statuses = currSignStatuses?.filter((item) => allStatus.includes(item.name))?.map((s) => s.transitions)?.reduce((a, b) => {
				const x = a || []
				return x.concat(b)
			}, []);

			const arrayUniqueByKey = statuses.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

			currState.signProbabilityStatuses = arrayUniqueByKey;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSignStatuses.fulfilled, (state, action) => {
			const currState = state;
			currState.signStatuses = action.payload?.results;
		});
	},
});

export const contentActions = contentSlice.actions;
export default contentSlice;
