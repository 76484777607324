import axios from "axios";
import config from "../components/Helper/config"

// const axiosInstance = axios.create({
//     baseURL: config.base_api,
//     headers: { Authorization: config.token, "Content-Type": "application/json" },
// });
class SignTypeApi {
    baseURL = config.base_api;
    token = config.token;

    getSignTypes(params) {
        return axios.get(this.baseURL + `sign_types/`, {
            params: params,
            headers: {
                Authorization: config.token,
                "Content-Type": "application/json",
            },
        });
    }

    getSignSubTypeDetail(id, params) {
        if (!id) return
        return axios.get(this.baseURL + `sign_sub_types/${id}/`, {
            params: params,
            headers: {
                Authorization: config.token,
                "Content-Type": "application/json",
            },
        });
    }

    getSignSubTypes(params) {
        return axios.get(this.baseURL + `sign_sub_types/`, {
            params: params,
            headers: {
                Authorization: config.token,
                "Content-Type": "application/json",
            },
        });
    }

    updateSignSubTypeDetail(id, data) {
        if (!id) return
        return axios.patch(this.baseURL + `sign_sub_types/${id}/`, data, {
            headers: {
                Authorization: config.token,
                "Content-Type": "application/json",
            },
        });
    }
}

export default SignTypeApi;
