import axios from "axios";
import qs from "qs"
import config from "../components/Helper/config"

class LoginApi {
	baseURL = config.base_api;
	token = config.token;

	authorize(params) {
		return axios.get(this.baseURL + `o/authorize/`, {
			params: params,
			headers: {
				// "Access-Control-Allow-Origin": "*",
				// "X-CSRF-TOKEN": Cookies.get("csrftoken"),
				// "Content-Type": "application/json",
			},
		});
	}

	async checkToken(data) {
		return await axios.post(this.baseURL + `o/introspect/`, qs.stringify(data), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: "Bearer " + data.token
			},
		});
	}

	async authorizeToken (code) {
		return await axios.post(this.baseURL + `o/token/`, qs.stringify(code), {
			params: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				// Accept: "application/json"
			},
		});
	}

	async authToken (data) {
		return await axios.post(this.baseURL + `o/token/`, qs.stringify(data), {
			params: {},
			auth: {
				username: 'Lqyt9OL5aLZ87Svr388jZ5cgWcmO0P4Hs1ZEgf5I',
				password: 'YTbBfjaR0mIwyUkonhpQyxzaaDV6ZtqNhZinaI74iVQZ96cV42Wvq7JK1fuwGl1JUxyUUOm1lxombabR7xvkODxVM3CpcMfQ0tpEsiEAfZDEdzZuJlc1ma5T75Ch0AXh'
			},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				// Accept: "application/json"
			},
		});
	}
	async revokeToken (data) {
		return await axios.post(this.baseURL + `o/revoke-token/`, qs.stringify(data), {
			params: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}
	async authRefreshToken (data) {
		return await axios.post(this.baseURL + `o/token/`, qs.stringify(data), {
			params: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async forgotPassword (email) {
		return await axios.post(this.baseURL + `password_reset/`, email, {
			params: {},
			headers: {
				"Content-Type": "application/json",
			},
		});
	}

	async resetPassword (data) {
		return await axios.post(this.baseURL + `password_reset/confirm/`, data, {
			params: {},
			headers: {
				"Content-Type": "application/json",
			},
		});
	}
}

export default LoginApi;
