import React, { StrictMode, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import CustomRoutes from './routes/Routes';
import { fallbackErrorRender } from './components/ErrorBoundary/ErrorBoundary';
import './i18n.ts';
import './index.css';
import store from './store';

process.env.REACT_APP_MODE === 'production' && ReactGA.initialize(process.env.REACT_APP_GA4);

process.env.REACT_APP_MODE === 'production' &&
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_MODE,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
			new Sentry.Replay(),
			new DedupeIntegration(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: [/<svg>/i, /<g>/i, /<path>/i, /Each child/i, /validateDOMNesting/i, /NaN/i, /defined/i, /recognize/i],
	});
const queryClient = new QueryClient({
	// Config
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: 1000 * 60 * 60 * 24,
		},
	},
});

const rootElement = document.getElementById('root');
ReactDOM.render(
	<StrictMode>
		<ErrorBoundary fallbackRender={fallbackErrorRender}>
			<QueryClientProvider client={queryClient}>
				<Suspense fallback={<div></div>}>
					<Provider store={store}>
						<BrowserRouter>
							<CustomRoutes></CustomRoutes>
						</BrowserRouter>
						<Toaster position='top-center' reverseOrder={true} />
					</Provider>
				</Suspense>
			</QueryClientProvider>
		</ErrorBoundary>
	</StrictMode>,
	rootElement
);
